
export const shardsTooltips: { [key: string]: string } = {
  '5008': '+9 Adaptive Force',
  '5005': '+10% Attack Speed',
  '5007': '+1 - 10% CDR (based on level)',
  '5002': '+6 Armor',
  '5003': '+8 Magic Resist',
  '5001': '+15 - 90 Healh (based on level)',
};

export const ShardsNames = ['Offense', 'Flex', 'Defense'];

export const RunesSlotsMapByID: { [key: string]: { slots: number[][] } } = {
  8000: {
    slots: [
      [8005, 8008, 8021, 8010],
      [9101, 9111, 8009],
      [9104, 9105, 9103],
      [8014, 8017, 8299],
    ],
  },
  8100: {
    slots: [
      [8112, 8124, 8128, 9923],
      [8126, 8139, 8143],
      [8136, 8120, 8138],
      [8135, 8134, 8105, 8106],
    ],
  },
  8200: {
    slots: [
      [8214, 8229, 8230],
      [8224, 8226, 8275],
      [8210, 8234, 8233],
      [8237, 8232, 8236],
    ],
  },
  8300: {
    slots: [
      [8351, 8358, 8360],
      [8306, 8304, 8313],
      [8321, 8316, 8345],
      [8347, 8410, 8352],
    ],
  },
  8400: {
    slots: [
      [8437, 8439, 8465],
      [8446, 8463, 8401],
      [8429, 8444, 8473],
      [8451, 8453, 8242],
    ],
  },
};
